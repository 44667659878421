<template>
      <div class="mt-5">
        <div class="row">
          <side-menu></side-menu>
          <div class="col-xl-9 col-lg-8">
            <div class="m-portlet">
              <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                  <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">Minha Conta</h3>
                  </div>
                </div>
              </div>
              <div method="post" action>
                <input type="hidden" name="_token" value="Vup64IcyttaolC4PnbhcrKTuSXtfQlpjxhXq2heF">
                <input type="hidden" name="_method" value="put">
                <div class="m-portlet__body">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group m-form__group">
                        <label for="">Nome</label>
                        <input
                          type="text"
                          v-model="user.first_name"
                          name="first_name"
                          class="form-control m-input"
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group m-form__group">
                        <label for="">Sobrenome</label>
                        <input
                          type="text"
                          v-model="user.last_name"
                          name="last_name"
                          class="form-control m-input"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6">
                      <div class="form-group m-form__group">
                        <label>Telefone</label>
                        <the-mask name="phone" v-model="user.phone"  class="form-control"  :mask="['(##) ####-####', '(##) #####-####']" />
                      </div>
                    </div>
                  </div>

                  <div class="m-portlet m-portlet--mobile m-portlet--body-progress-">
                    <div class="m-portlet__head">
                      <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                          <h3 class="m-portlet__head-text">
                            Minha senha
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="m-portlet__body">
                      <div class="row">
                      <div class="col-6">
                        <div class="form-group m-form__group">
                          <label for="">Senha</label>
                          <input autocomplete="off" type="password" v-model="user.password" class="form-control m-input">
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group m-form__group">
                          <label for="">Confirmar Senha</label>
                          <input autocomplete="off" type="password" v-model="user.password_confirmation" class="form-control m-input">
                        </div>
                      </div>
                    </div>
                  </div>
								</div>

                </div>
                <div class="m-portlet__foot">
                  <div class="row align-items-right text-right">
                    <div class="col-lg-12">
                      <button type="buttom" class="btn btn-brand" @click="update">Salvar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import SideMenu from "../components/SideMenu";
export default {
	name: "profile",
	components: {
		SideMenu
	},
	data() {
		return {
			user: {
				first_name: "",
				last_name: "",
        phone: "",
				password: "",
        password_confirmation: "",
      },
		};
  },
	methods: {
		getUser() {
			let user = this.$store.state.auth.user;
			this.user.first_name = user.firstName;
			this.user.last_name = user.lastName;
      this.user.phone = user.phone;
		},
		update() {
			axios
				.put(`/user/${this.$store.state.auth.user.id}/profile`, this.user)
				.then((response) => {
          this.$store.commit('setUser', response.data.user);
          swal("Sucesso!", "Cadastro atualizado com sucesso!", "success");
          this.user.password = '';
          this.user.password_confirmation = '';
				})
				.catch(error => {
					if (error.response && error.response.data) {
						let errorKeys = Object.keys(error.response.data.errors);
						let errorMessages = "<h5>Verifique os erros!</h5>";
						errorKeys.forEach(key => {
							errorMessages += `<li class="text-left">${
								error.response.data.errors[key][0]
							}</li>`;
						});
						swal("Ops!", errorMessages, "error");
					}
				});
    },
	},
	created() {
		this.getUser();
	}
};
</script>

<style>
</style>
