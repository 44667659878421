<template>
	<div class="col-xl-3 col-lg-4">
		<div class="m-portlet">
			<div class="m-portlet__body">
				<div class="m-card-profile">
					<div class="m-card-profile__title m--hide">Your Profile</div>
					<div class="m-card-profile__pic">
						<div @click="() => $refs.user_avatar.click()" id="user-avatar" class="m-card-profile__pic-wrapper" style="cursor: pointer;">
							<img v-if="$store.state.auth.user.avatarUrl" ref="avatar" id="user-avatar-stored" :src="$store.state.auth.user.avatarUrl">
							<img v-else id="user-avatar-stored" ref="avatar" src="@/assets/img/user-placeholder.png">
							<input type="file" ref="user_avatar" style="display:none" @change="saveAvatar">
						</div>
						<br>
						
						<div id="user-avatar-update" class="mb-3" style="margin-top: -20px; display: none;">
							<form action method="post" enctype="multipart/form-data">
								<input type="hidden" name="_token" value="Vup64IcyttaolC4PnbhcrKTuSXtfQlpjxhXq2heF">
								<input type="hidden" name="_method" value="patch">
								<input
									type="file"
									accept="image/*"
									name="avatar"
									id="user-avatar-input"
									style="display: none;"
								>
								<button
									type="button"
									onclick="window.location.reload()"
									class="btn btn-secondary btn-sm"
								>Cancelar</button>
								<button type="submit" class="btn btn-success btn-sm">Salvar</button>
							</form>
						</div>
					</div>
					<div class="m-card-profile__details">
						<span class="m-card-profile__name">{{ `${$store.state.auth.user.firstName} ${$store.state.auth.user.lastName}` }}</span>
						<a href class="m-card-profile__email m-link">{{ $store.state.auth.user.email }}</a>
					</div>
				</div>
				<ul class="m-nav m-nav--hover-bg m-portlet-fit--sides">
					<li class="m-nav__separator m-nav__separator--fit"></li>
					<li class="m-nav__section m--hide">
						<span class="m-nav__section-text">Section</span>
					</li>
					<li :class="setActive('profile') ? 'm-nav__item m-nav__item--active' : 'm-nav__item m-nav__item--'">
						<router-link :to="{ name: 'profile' }" class="m-nav__link">
							<i class="m-nav__link-icon flaticon-user"></i>
							<span class="m-nav__link-title">
								<span class="m-nav__link-wrap">
									<span class="m-nav__link-text">Minha Conta</span>
								</span>
							</span>
						</router-link>
					</li>
					<li v-if="$authorization.can('users')" :class="setActive('users') ? 'm-nav__item m-nav__item--active' : 'm-nav__item m-nav__item--'">
            <router-link :to="{ name: 'users' }" class="m-nav__link">
              <i class="m-nav__link-icon flaticon-users"></i>
              <span class="m-nav__link-title">
								<span class="m-nav__link-wrap">
									<span class="m-nav__link-text">Usuários</span>
								</span>
							</span>
            </router-link>
          </li>
          <li v-if="$authorization.can('acl-group')" :class="setActive('acl') ? 'm-nav__item m-nav__item--active' : 'm-nav__item m-nav__item--'">
            <router-link :to="{ name: 'acl' }" class="m-nav__link">
              <i class="m-nav__link-icon flaticon-cogwheel-1"></i>
              <span class="m-nav__link-title">
								<span class="m-nav__link-wrap">
									<span class="m-nav__link-text">Regras e Permissões</span>
								</span>
							</span>
            </router-link>
          </li>
					<li v-if="$authorization.can('user-invite-user')" :class="setActive('invite') ? 'm-nav__item m-nav__item--active' : 'm-nav__item m-nav__item--'">
						<router-link :to="{ name: 'invite' }" class="m-nav__link">
							<i class="m-nav__link-icon flaticon-paper-plane"></i>
							<span class="m-nav__link-title">
								<span class="m-nav__link-wrap">
									<span class="m-nav__link-text">Convidar Usuário</span>
								</span>
							</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "side-menu",
  methods: {
    setActive(route) {
      return route == this.$route.name
		},
		saveAvatar() {
			const file = this.$refs.user_avatar.files[0];
			if(file) {
				let reader = new FileReader();
				 reader.onload = (e) => {
              this.$refs.avatar.src = e.target.result;
           }

           reader.readAsDataURL(file);
			 }

			 let formData = new FormData();
			 formData.append("avatar", file);

			 const user_id = this.$store.state.auth.user.id;

			 axios.post(`user/${user_id}/avatar`, formData)
		 	.then((response) => {
				 this.$store.commit('setUser', response.data.user);
				})
		 	.catch((error) => {
				 console.log(error);
				 swal('Ops!', 'Não foi possível atualizar o avatar', 'error')
			 });
		},
  }
};
</script>

<style>
</style>
